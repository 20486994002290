import React from 'react';

const MainComponent = () => {
  return (    
    <div id="page-wrapper">	
        <div id="header-wrapper">
            <header id="header" class="container">                
                    <div id="logo">
                        <h1><a href="index.html">Financial<br />Surveys</a></h1>
                        <span>.tech</span>
                    </div>

                   <nav id="nav">
                        <ul>
                            <li class="current"><a href="/">Welcome</a></li>
                            <li><a href="/signup/">Sign-up</a></li>                                                        
                        </ul>
                    </nav>

            </header>
        </div>

        <div id="banner-wrapper">
            <div id="banner" class="box container">
                <div class="row">
                    <div class="col-7 col-12-medium">
                        <h2>Link a financial account. Take surveys. Get paid. More.</h2>
                        <p>Financial service companies shouldn't be the only ones that make money from from your financial data.</p>
                    </div>
                    <div class="col-5 col-12-medium">
                        <ul>
                            <li><a href="/signup/" class="button large icon solid fa-arrow-circle-right">Sign-up now</a></li>
                            <li><a href="#" class="button alt large icon solid fa-question-circle">How does it work</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    
        <div id="features-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-4 col-12-medium">
                        
                            <section class="box feature">
                                <a href="#" class="image featured"><img src="images/image1.png" alt="" /></a>
                                <div class="inner">
                                    <header>
                                        <h2>Join our panel by linking at least one loan or debt.</h2>                                        
                                    </header>
                                    <p>We use methodfi.com's technology to obtain your permission to read the financial accounts you choose to link. We never share or use any financial data without your explicit consent.</p>
                                </div>
                            </section>

                    </div>
                    <div class="col-4 col-12-medium">
                        
                            <section class="box feature">
                                <a href="#" class="image featured"><img src="images/image2.png" alt="" /></a>
                                <div class="inner">
                                    <header>
                                        <h2>We search for academic surveys that need participants with your financial background.</h2>                                        
                                    </header>
                                    <p>Whether it's for people who have credit card debt, accounts at a credit union or student loans, we identify academic researchers that are looking to pay a premium for survey participants that have your financial background.</p>
                                </div>
                            </section>

                    </div>
                    <div class="col-4 col-12-medium">
                        
                            <section class="box feature">
                                <a href="#" class="image featured"><img src="images/image3.png" alt="" /></a>
                                <div class="inner">
                                    <header>
                                        <h2>You complete the survey, and get paid. More.</h2>                                        
                                    </header>
                                    <p>Unless told otherwise, we do not share your financial information with the academic researchers who post studies.</p>
                                </div>
                            </section>

                    </div>
                </div>
            </div>
        </div>

        <div id="main-wrapper">
            <div class="container">
                <div class="row gtr-200">
                    <div class="col-4 col-12-medium">
                        
                            <div id="sidebar">
                                <section class="widget thumbnails">
                                    <h3>Condimentum curae</h3>
                                    <div class="grid">
                                        <div class="row gtr-50">
                                            <div class="col-6"><a href="#" class="image fit"><img src="images/pic04.jpg" alt="" /></a></div>
                                            <div class="col-6"><a href="#" class="image fit"><img src="images/pic05.jpg" alt="" /></a></div>
                                            <div class="col-6"><a href="#" class="image fit"><img src="images/pic06.jpg" alt="" /></a></div>
                                            <div class="col-6"><a href="#" class="image fit"><img src="images/pic07.jpg" alt="" /></a></div>
                                        </div>
                                    </div>
                                    <a href="#" class="button icon fa-file-alt">More</a>
                                </section>
                            </div>

                    </div>
                    <div class="col-8 col-12-medium imp-medium">
                        
                            <div id="content">
                                <section class="last">
                                    <h2>Who is behind FinancialSurveys.tech?</h2>
                                    <p><a href="http://www.simonjblanchard.com">Simon Blanchard</a>, Dean's Professor and Provost Distinguished Associate Professor, created financialsurveys.tech. Kelly L. Martin (Colorado State University) and Linda C. Salisbury (Boston College) serve as sounding boards, and <a HREF="https://www.linkedin.com/in/indrajit-alex/">Indrajit Alex</a> provides help with devops and the backend.</p><br />
                                    <a href="#" class="button icon solid fa-arrow-circle-right">Continue Reading</a>
                                </section>
                            </div>

                    </div>
                </div>
            </div>
        </div>

        <div id="footer-wrapper">
            <footer id="footer" class="container">
                <div class="row">
                    <div class="col-3 col-6-medium col-12-small">
                        
                            <section class="widget links">
                                <h3>Privacy</h3>
                                <ul class="style2">
                                    <li><a href="#">Privacy Policy</a></li>                                    
                                </ul>
                            </section>

                    </div>
                    <div class="col-3 col-6-medium col-12-small">
                        
                            <section class="widget links">
                                <h3>How it works</h3>
                                <ul class="style2">
                                    <li><a href="#">What data do we get?</a></li>
                                    <li><a href="#">Who obtains that data for us?</a></li>
                                    <li><a href="#">Who has access to your data?</a></li>
                                    <li><a href="#">How do you delete your data?</a></li>
                                </ul>
                            </section>

                    </div>
                    <div class="col-3 col-6-medium col-12-small">
                        

                    </div>
                    <div class="col-3 col-6-medium col-12-small">
                        
                            <section class="widget contact last">
                                <h3>Contact Us</h3>
                                <ul>
                                    <li><a href="https://twitter.com/SIMONJBLANCHARD" class="icon brands fa-twitter"><span class="label">Twitter</span></a></li>                                    
                                    <li><a href="https://www.linkedin.com/in/blasimon/" class="icon brands fa-linkedin"><span class="label">LinkedIn</span></a></li>                                    
                                </ul>
                                <p>McDonough School of Business<br />
                                Georgetown University<br />
                                Washington, DC 20057<br />
                                (202) 687-6977</p>
                            </section>

                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div id="copyright">
                            <ul class="menu">
                                <li>&copy; Untitled. All rights reserved</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>

    </div>
    



  );
};

export default MainComponent;