import React, { useState } from 'react';
import { useMethod } from 'react-method-elements';
import { baseUrl } from '../services/api';
import '../styles/css/main.css';

const SignUpComponent = () => {
  const [displayComponent, setDisplayComponent] = useState('DefaultComponent');

  const myAPI = {
    createElementToken: async (entity_id) => {
      try {
        const response = await fetch(`${baseUrl}/get-token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ entity_id }),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error fetching token:', error);
        return null;
      }
    },

    createEntity: async (formData) => {
      try {
        const entityData = {
          type: 'individual',
          individual: {
            first_name: formData.first_name,
            last_name: formData.last_name,
            phone: formData.phone,
            email: formData.email,
            dob: '1991-03-18',
          },
          address: {
            line1: '3300 N Interstate 35',
            line2: null,
            city: 'Austin',
            state: 'TX',
            zip: '78705'
          }
        };
        console.log('Entity from form:', entityData);
        const response = await fetch(`${baseUrl}/create-entity`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(entityData),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error fetching token:', error);
        return null;
      }
    },
  };

  const method = useMethod({
    env: 'dev',
    onEvent: (payload) => {},
    onSuccess: (payload) => {
      setDisplayComponent('NewComponent'); // What to call when linking is complete
    },
    onError: (payload) => {},
    onExit: (payload) => {
      console.log('Now we exit');
    },
    onOpen: (payload) => {},
  });

  if (!method) return null;

  const handleEvent = (event) => {
    // Update displayComponent based on the event
    setDisplayComponent('NewComponent');
  };

  const onClick = async (event) => {
    event.preventDefault();

    const form = document.getElementById('entityForm');
    const formData = {
      first_name: form.first_name.value,
      last_name: form.last_name.value,
      phone: form.phone.value,
      email: form.email.value,
    };
    console.log('Form data:', formData);

    const response = await myAPI.createEntity(formData);

    if (response && response.id) {
      const entity_id = response.id;
      const tokenResponse = await myAPI.createElementToken(entity_id);
      const token = tokenResponse.token.element_token;
      method.open(token);
    }
  };

  return (
    <div>
    <div id="page-wrapper">
        <div id="header-wrapper">
            <header id="header" class="container">
                    <div id="logo">
                        <h1><a href="index.html">Financial<br />Surveys</a></h1>
                        <span>.tech</span>
                    </div>

                    <nav id="nav">
                        <ul>
                            <li><a href="/">Welcome</a></li>
                            <li class="current"><a href="/signup/">Sign-up</a></li>
                        </ul>
                    </nav>

            </header>
        </div>

        <div id="main-wrapper">
            <div class="container">
                <div id="content">

                        <article>

                          {displayComponent === 'DefaultComponent' && <DefaultComponent />}
                          {displayComponent === 'NewComponent' && <NewComponent />}

                          {displayComponent === 'DefaultComponent' && (
                            <form id="entityForm">
                              <input type="text" name="first_name" placeholder="Kevin" value="Kevin" readOnly />
                              <input type="text" name="last_name" placeholder="Doyle" value="Doyle" readOnly />
                              <input type="text" name="phone" placeholder="+15121231111" value="+15121231111" readOnly />
                              <input type="email" name="email" placeholder="kevin.doyle@gmail.com" value="kevin.doyle@gmail.com" readOnly />
                              <button onClick={onClick}>Submit</button>
                            </form>
                          )}


                        </article>

                </div>
            </div>
        </div>

        <div id="footer-wrapper">
            <footer id="footer" class="container">
                <div class="row">
                    <div class="col-3 col-6-medium col-12-small">
                        
                            <section class="widget links">
                                <h3>Privacy</h3>
                                <ul class="style2">
                                    <li><a href="#">Privacy Policy</a></li>                                    
                                </ul>
                            </section>

                    </div>
                    <div class="col-3 col-6-medium col-12-small">
                        
                            <section class="widget links">
                                <h3>How it works</h3>
                                <ul class="style2">
                                    <li><a href="#">What data do we get?</a></li>
                                    <li><a href="#">Who obtains that data for us?</a></li>
                                    <li><a href="#">Who has access to your data?</a></li>
                                    <li><a href="#">How do you delete your data?</a></li>
                                </ul>
                            </section>

                    </div>
                    <div class="col-3 col-6-medium col-12-small">
                        

                    </div>
                    <div class="col-3 col-6-medium col-12-small">
                        
                            <section class="widget contact last">
                                <h3>Contact Us</h3>
                                <ul>
                                    <li><a href="https://twitter.com/SIMONJBLANCHARD" class="icon brands fa-twitter"><span class="label">Twitter</span></a></li>                                    
                                    <li><a href="https://www.linkedin.com/in/blasimon/" class="icon brands fa-linkedin"><span class="label">LinkedIn</span></a></li>                                    
                                </ul>
                                <p>McDonough School of Business<br />
                                Georgetown University<br />
                                Washington, DC 20057<br />
                                (202) 687-6977</p>
                            </section>

                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div id="copyright">
                            <ul class="menu">
                                <li>&copy; Untitled. All rights reserved</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>

    </div>



    </div>
  );
};

const DefaultComponent = () => {
  return (
    <>
      <h2>Link your financial account</h2>
      <p>Here's what you need to tell us.</p>
    </>
  );
};

const NewComponent = () => {
  return (
    <>
      <h2>Thank you! You are subscribed to our panels.</h2>
      <p>You will be alerted once a survey is available.</p>
    </>
  );
};

export default SignUpComponent;